.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/**** Login Page Css *****/

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

/****   Login Page Css *******/

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

body {
  background-color: #080710;
}

.background {
  width: 430px;
  height: 520px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.background .shape {
  height: 200px;
  width: 200px;
  position: absolute;
  border-radius: 50%;
}

.shape:first-child {
  background: linear-gradient(#1845ad,
      #23a2f6);
  left: -80px;
  top: -80px;
}

.shape:last-child {
  background: linear-gradient(to right,
      #ff512f,
      #f09819);
  right: -30px;
  bottom: -80px;
}

.loginregister form {
  /* height: 520px; */
  width: 400px;
  background-color: rgba(255, 255, 255, 0.13);
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
  padding: 20px 35px;
}

form * {
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  letter-spacing: 0.5px;
  outline: none;
  border: none;
}

form h3 {
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
  text-align: center;
}

label {
  display: block;
  margin-top: 15px;
  font-size: 16px;
  font-weight: 500;
  color: #222
}

input {
  display: block;
  height: 50px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 3px;
  padding: 0 10px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 300;
  border: 1px solid #666;
  color: #222;
}

::placeholder {
  color: #ccc;
}

button {
  margin-top: 25px;
  width: 100%;
  background-color: #ffffff;
  color: #080710;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}

.social {
  margin-top: 30px;
  display: flex;
}

.social div {
  background: red;
  width: 150px;
  border-radius: 3px;
  padding: 5px 10px 10px 5px;
  background-color: rgba(255, 255, 255, 0.27);
  color: #eaf0fb;
  text-align: center;
}

.social div:hover {
  background-color: rgba(255, 255, 255, 0.47);
}

.register_area {
  padding-top: 20px;
  text-align: right;
}

.register_area a {
  text-decoration: none;
}

.text-white {
  color: #fff;
}


/**** Side Bar style ****/

.sidebar {
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 99;
  padding-bottom: 50px;
  position: fixed;
  width: 270px;
  z-index: 999;
  overflow: hidden;
  overflow-y: auto;
  background: #fff;
  box-shadow: 0 12px 30px rgba(80, 143, 244, .1);
  transition: .3s;
}

.sidebar .logo {
  margin: 0;
  background: #f3f6f9;
  padding: 24px;
  margin-bottom: 20px;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

.sidebar #sidebar_menu>li {
  margin-left: 0;
}

.sidebar #sidebar_menu>li a {
  font-size: 16px;
  font-weight: 400;
  color: #144339;
  padding: 0 25px 0 0;
  background: #fff;
  transition: .3s;
  position: relative;
  z-index: 0;
  display: grid;
  grid-template-columns: 34px auto;
  grid-gap: 15px;
  align-items: center;
  position: relative;
  background: 0 0;
  color: #8890b5;
  font-family: mulish, sans-serif;
  padding: 10px 10px 10px 0px;
  background: #fff;
  border-radius: 15px;
  text-decoration: none;
}

.sidebar #sidebar_menu>li>a {
  color: #144339;
}

.sidebar #sidebar_menu li a img {
  width: 14px;
  height: auto;
}

.main_content {
  padding-left: 270px;
  width: 100%;
  padding-top: 0 !important;
  transition: .5s;
  position: relative;
  min-height: 100vh;
  padding-bottom: 99px;
  overflow: hidden;
}

.main_content .main_content_iner {
  min-height: 68vh;
  transition: .5s;
  position: relative;
  background: #fbf6f0;
  margin: 0;
  z-index: 22;
  border-radius: 0;
  padding: 30px;
}

.nav_icon {
  color: #222;
}

.metismenu .has-arrow::after {
  position: absolute;
  content: '';
  transform: rotate(-90deg) translate(0, -50%);
  transform-origin: top;
  top: 50%;
  transition: all .3s ease-out;
  right: 40px;
  content: "\e64b";
  font-family: themify;
  color: #a5adc6;
  font-weight: 600;
  font-size: 14px;
}

.sidebar #sidebar_menu>li a::after {
  color: #fff;
  transform: rotate(-90deg) translate(0, -50%);
  opacity: 1;
  font-size: 10px;
  right: 15px;
  color: #a1a4b9;
}

.bg_white_inner {
  background-color: #fff;
}

/**** Top Header ****/

.topbar_area {
  background-color: #128c82;
  padding: 12px 30px;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.humberger_menu a {
  color: #fff;
}

.right_notification_section ul {
  margin: 0;
  padding: 0;
}

.right_notification_section li {
  list-style: none;
  display: inline-block;
  padding-left: 30px;
}

.right_notification_section li a {
  color: #fff;
}

.page_content {
  padding: 20px 20px;
}

.table_data {
  width: 100%;
}

.maintable {
  width: 100%;
  border: 1px solid #efefef;
}

.maintable thead {
  background-color: #efefef;
}

.maintable th,
.maintable td {
  padding: 10px 10px;
}

.maintable tr:nth-child(even) {
  background-color: #efefef;
}

.modal-container {
  width: 560px;
  margin: 0 auto;
  background: #fff;
  padding: 30px;
  margin-top: 50px;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.metismenu ul {
  padding-left: 0px;
}

.metismenu li {
  list-style: none;
}

.modal-header.w-100 {
  width: 100%;
}